<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV2.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar Kepemilikan',
      dataurl:'/ownership',
      baseroute:'kepemilikan',
      fields:[
        { key: 'name', label: 'Jenis Kepemilikan', sortable: true},
      ],
    }
  },
}
</script>